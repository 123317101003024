<template>
  <div class="warningListManage">
    <Breadcrumb :title="$route.meta.title"/>
    <section class="filterWrap">
      <!-- 時間 -->
      <div class="itemWrap">
        <label>查詢日期：</label>
        <el-date-picker :editable="false" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
          start-placeholder="開始日期" 
          end-placeholder="結束日期"
          v-model="rangeDate"
        ></el-date-picker>
      </div>
      <!-- 類型人名或組織 -->
      <div class="itemWrap">
        <label>名單類型：</label>
          <el-select v-model="listQuery.Type" placeholder="請選擇名單類型" no-data-text="目前無名單" clearable>
            <el-option v-for="item in typeList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
      </div>
      <!-- 名單類型 -->
      <div class="itemWrap">
        <label>名單屬性：</label>
        <el-select v-model="listQuery.Attribute" multiple clearable placeholder="請選擇名單屬性" no-data-text="目前無屬性">
          <el-option v-for="item in attributeSelectData" :key="item"
            :value="item"
            :label="item"
          ></el-option>
        </el-select>
      </div>
      <!-- input警示名單名稱 -->
      <div class="itemWrap">
        <label>名單名稱：</label>
        <el-input v-model.trim="listQuery.Name" placeholder="請輸入名單名稱"></el-input>
      </div>
    </section>
    <section class="btnWrap">
      <el-button @click="doSearch" type="primary" class="searchLog" icon="el-icon-search">查詢</el-button>
      <el-button @click="toggleDialog" type="primary" class="searchLog" icon="el-icon-plus" v-if="userRole==='operator'">新增警示名單</el-button>
    </section>
    <div class="manageTable">
      <el-table :data="tableData" border style="width: 100%" max-height="400">
        <el-table-column label="項次" type="index" width="80"></el-table-column>
        <el-table-column prop="ID" label="名單編號" width="50"></el-table-column>
        <el-table-column prop="ListType" label="名單類型" width="80">
           <template slot-scope="scope">
              {{ transListType(scope.row.ListType) }}
          </template>
        </el-table-column>
        <el-table-column prop="Name" label="名單名稱" width="180">
          <template slot-scope="scope">
            <a class="link" @click="routerPushEditForm(scope.row)">
              {{ scope.row.Name }}
            </a>
          </template>
        </el-table-column>
        <el-table-column prop="Attribute" label="名單屬性">
          <template slot-scope="scope">
              {{ attribute(scope.row.Attribute) }}
          </template>
        </el-table-column>
        <el-table-column prop="CreateDt" label="建立時間" width="250">
          <template slot-scope="scope">
            <div>
              {{ filterDate(scope.row.CreateDt) }}
            </div>
          </template></el-table-column
        >
      </el-table>
    </div>

    <Pagination
      @currentChange="pageChange"
      :pageSize="this.listQuery.Page_Size"
      :propsCurrentPage="this.listQuery.Page_Num"
      :totalLists="totalLists"
    ></Pagination>

    <div v-if="openPopUp" class="warningListPopUp">
      <div class="popup">
        <i class="el-icon-close" @click="toggleDialog"></i>
        <p>請選擇要新增的名單類別</p>
        <div>
          <el-button type="danger" @click="routerPush('people','add')">人名</el-button>
          <el-button type="danger" @click="routerPush('org','add')">組織</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue"
import Pagination from "@/components/Pagination.vue";
export default {
  components: {Breadcrumb,Pagination },
  data() {
    return {
      openPopUp: false,
      userRole: this.$store.state.userInfo.Role,
      rangeDate:[],
      totalLists: null,
      typeList: [
        {
          value: ["people"],
          label: "警示人名",
        },
        {
          value: ["org"],
          label: "警示組織",
        },
      ],
      attributeSelectData:[],
      warningListManage: true,
      listQuery: {
        UserID: this.$store.state.userInfo.UserID,
        CreateCom: [this.$store.state.userInfo.ComName],//使用者的公司
        CreateDept: [],//使用者的部門
        Type: [],//(單選)名單類別people||org
        Start_Date: "",//時間
        End_Date: "",//時間
        Attribute: [],//名單屬性
        Name: "",//input警示名單
        Page_Size: 10, //每頁有幾筆
        Page_Num: 1, //目前頁數
        Status: ['approval'],//(複選)名單狀態
      },
      value: "",
      attributesList: [],
      tableData: [],
      textValue: "",
      checkedListQuery: {},
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
    transListType(){
      let text = "無";
      return (En) => {
        if(En==='people'){
          text = '人名'
        }else if(En==='org'){
          text = '組織'
        }
        return text 
      };
    },
    attribute(){
      let text = ''
      return (attribute) => {
        if(attribute.length>0){
          text = attribute.join("、")
        }else{
          text = '無'
        }
        return text
      };
    }
  },
  methods: {
    doSearch(){
      this.$store.dispatch("loadingHandler", true);
      this.listQuery.Page_Num = 1;
      this.deleteNullValue();
      let temp = JSON.parse(JSON.stringify(this.listQuery))
      temp.IsUser = true;
      // this.getList()
      this.$api.list.searchList(temp).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
    },
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },
    toggleDialog() {
      this.openPopUp = !this.openPopUp;
    },
    routerPush(formType,actionsType) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:actionsType,
          warningFormType: formType,//people或 org
        }
      });
    },
    routerPushEditForm(item) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:item.Status,
          permission:item.Permission,
          warningFormType:item.ListType,
          listID:item.ListID,
        }
      });
    },
    getAttributeSelect(){
      this.$api.system.getOptions({Type:["ListAttribute"]}).then((res)=>{
        this.attributeSelectData = res.ListAttribute
      })
    },
    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
      
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      this.deleteNullValue();
      this.$api.list.searchList(this.listQuery).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
    },
  },
  mounted() {
    this.getList();
    this.getAttributeSelect();
  },
};
</script>

<style lang="scss" scoped>
.warningListManage {
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  padding: 0px 20px;
  min-height: 100vh;
  .manageTable {
    margin-top: 32px;
    .link {
      cursor: pointer;
      color: blue;
    }
  }
  .filterWrap{
    .itemWrap{
      margin-bottom: 10px;
      width: 500px;
      display: flex;
      align-items: center;
      label{
        width:16%;
      }
      &:nth-child(2),&:nth-child(3){
        .el-select{
          width: 50%;
        }
      }
      &:nth-child(4){
        .el-input{
          width: 50%;
        }
      }
    }
    
  }
  .btnWrap{
    display: flex;
    justify-content: space-between;
  }
  .warningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
</style>
